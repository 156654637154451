exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-nemryk-mdx": () => import("./../../../src/pages/articles/nemryk.mdx" /* webpackChunkName: "component---src-pages-articles-nemryk-mdx" */),
  "component---src-pages-articles-odpowiedz-mdx": () => import("./../../../src/pages/articles/odpowiedz.mdx" /* webpackChunkName: "component---src-pages-articles-odpowiedz-mdx" */),
  "component---src-pages-articles-sondarze-mdx": () => import("./../../../src/pages/articles/sondarze.mdx" /* webpackChunkName: "component---src-pages-articles-sondarze-mdx" */),
  "component---src-pages-articles-szok-mdx": () => import("./../../../src/pages/articles/SZOK.mdx" /* webpackChunkName: "component---src-pages-articles-szok-mdx" */),
  "component---src-pages-articles-test-mdx": () => import("./../../../src/pages/articles/test.mdx" /* webpackChunkName: "component---src-pages-articles-test-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-articles-nemryk-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/buildhome/repo/src/pages/articles/nemryk.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-articles-nemryk-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-articles-odpowiedz-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/buildhome/repo/src/pages/articles/odpowiedz.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-articles-odpowiedz-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-articles-sondarze-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/buildhome/repo/src/pages/articles/sondarze.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-articles-sondarze-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-articles-szok-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/buildhome/repo/src/pages/articles/SZOK.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-articles-szok-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-articles-test-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/buildhome/repo/src/pages/articles/test.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-articles-test-mdx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */)
}

